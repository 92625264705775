.info {
  display: flex;
  justify-content: center;
  padding-top: 5rem;
  height: 100vh;
  background: #95aa96;
}

.info-story {
  display: grid;
  align-content: start;
  border-radius: 10px;
  max-width: 640px;
  width: 90%;
  height: 680px;
  margin: 1rem;
  box-shadow: 0 6px 20px rgba(63, 63, 63, 0.5);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  background: #fafffb;
  padding: 2rem;
}

.info-story p,
h1,
h2 {
  text-align: left;
  white-space: pre-line;
}

p.info-description {
  line-height: 1.3rem;
  color: #373146;
}

h1.info-header {
  padding-bottom: 1rem;
  color: rgb(77, 106, 121);
}

h2.info-header {
  padding-bottom: 0.5rem;
  padding-top: 2rem;
  font-size: 1.2em;
  color: rgb(77, 106, 121);
}
