.gardenlist-gardens {
  /* background: #fff;
  display: grid;
  justify-content: center;
  padding-top: 5rem; */
  background: #fff;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 1rem;
  justify-content: center;
  padding-top: 5rem;
}
