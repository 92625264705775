.imagemodal-background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 1);
  top: 0px;
  left: 0px;
}

.imagemodal-container {
  display: flex;
  flex-direction: column;
}

.imagemodal-image {
  border-radius: 22px;
  width: 100%;
  max-width: 640px;
  padding: 10px;
  cursor: pointer;
}

.imagemodal-caption {
  color: black;
}

.imagemodal-copyright {
  color: lightgrey;
  font-size: 75%;
  font-style: italic;
}

.imagemodal-close {
  text-align: right;
  padding-right: 10px;
  cursor: pointer;
  color: grey;
}

.imagemodal-next,
.imagemodal-prev {
  cursor: pointer;
  color: grey;
}
