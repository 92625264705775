.map {
  background: #fafffb;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.map-frame {
  border: none;
  border-radius: 10px;
  max-width: 640px;
  width: 90%;
  height: 680px;
  margin: 1rem;
  box-shadow: 0 6px 20px rgba(63, 63, 63, 0.5);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
}
