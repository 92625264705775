.garden-main {
  background: #fff;
  text-align: center;
  display: grid;
  justify-items: center;
  width: 100%;
  background: #95aa96;
}

.garden-wrapper {
  max-width: 700px;
  min-width: 300px;
  border-radius: 10px;
  background: #fafffb;
  margin-top: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 6px 20px rgba(63, 63, 63, 0.5);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
}

.garden-caption {
  display: inline-flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.garden-description {
  line-height: 1.3rem;
  text-align: justify;
  white-space: pre-line;
  padding: 1rem;
}

.garden-extra {
  margin-bottom: 2rem;
}

.garden-extras {
  text-align: left;
  padding: 0.2rem;
  margin-left: 2rem;
}

.garden-owner {
  color: grey;
  font-style: italic;
  margin-bottom: 0.5rem;
}

h2.garden-header {
  padding-left: 1rem;
  padding-top: 2rem;
  font-size: 1.2em;
}

.garden-images {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  max-width: 700px;
}

.gardenimage-image {
  border-radius: 10px;
  width: 320px;
  margin: 10px;
  cursor: pointer;
}

.garden-prev-button {
  color: grey;
  cursor: pointer;
  margin-top: 0.6rem;
  margin-right: 0.6rem;
}

.garden-next-button {
  color: grey;
  cursor: pointer;
  margin-top: 0.6rem;
  margin-left: 0.6rem;
}
